import axios from "@axios";

export default {
  namespaced: true,
  actions: {
    list: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get("input_prices", { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    show: (cntx, { id }) => {
      axios
        .get(`input_prices/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    },
    create: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .post("input_prices", params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    update: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .put(`input_prices/${params.id}`, params.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    remove: (cntx, id) => {
      return new Promise((resolve, reject) => {
        axios
          .delete(`input_prices/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    seasons: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get("seasons", { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    inputs: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get("inputs", { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    currencies: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get("currencies", { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    locations: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get("locations", { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
